export interface IAccordionItem {
  titleSlot: string;
  contentSlot: string;
  componentId?: string;
  isOpen?: boolean;
  classes?: string;
  activeBarColor?: string;
  activeBarTextColor?: string;
  barColor?: string;
  barTextColor?: string;
}

export enum EAccordionContentType {
  TEMPLATE = 'template',
  WYSIWYG = 'wysiwyg',
  TEXT = 'text',
}
